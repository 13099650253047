<template>
  <div>
    <b-overlay :show="loading">
      <b-card
        class="mb-3"
        :border-variant="card.isHanging ? 'danger' : 'default'"
        :header="card.isHanging ? 'Экстренно нужен ваш ответ ' : ''"
        header-bg-variant="danger"
        header-text-variant="white"
      >
        <b-card-title v-if="card.name">
          <b-button
            rel="nofollow noopener"
            target="_blank"
            :href="'https://www.google.com/search?q=' + card.name.replace(/\s+/g, '+') + '&gl=us&hl=en&pws=0&gws_rd=cr'"
          >
            <b-icon icon="search" />
          </b-button>
          {{ card.name }}
        </b-card-title>
        <card-vote
          v-if="card.id"
          :card="card"
          :show-revote-msg="true"
        >
          <template v-slot="{ canVote, voteAsin, voteProblem, isAlert }">
            <b-button
              class="mr-2 mb-2"
              :disabled="!canVote"
              variant="primary"
              @click="voteAndNext(voteAsin, voteProblem, isAlert)"
            >
              Next
            </b-button>
            <b-button
              class="mr-2 mb-2"
              :disabled="card.isHanging"
              @click="skip()"
            >
              Skip
            </b-button>
          </template>
        </card-vote>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import ApiService from '@/ApiService'
import { getRequestErrorText } from '@/utils'
import CardVote from '@/components/CardVote'

export default {
  name: 'Home',
  components: { CardVote },
  data () {
    return {
      loading: false,
      card: {}
    }
  },
  created () {
    this.$root.$on('CardClosed', this.handleCardClosed)
  },
  mounted () {
    this.next()
  },
  destroyed () {
    this.$root.$off('CardClosed', this.handleCardClosed)
  },
  methods: {
    next () {
      this.loading = true

      ApiService.get('/card')
        .then(response => {
          this.card = response.data
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            this.$router.push({ name: 'History' })
          } else {
            this.$bvToast.toast(getRequestErrorText(error), {
              title: 'Load next error',
              variant: 'danger'
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    skip () {
      this.loading = true

      ApiService.get('/card', null, {
        params: {
          not: this.card.id
        }
      })
        .then(response => {
          this.card = response.data
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            this.$bvToast.toast('This card is the last one', {
              title: 'Warning',
              variant: 'warning'
            })
          } else {
            this.$bvToast.toast(getRequestErrorText(error), {
              title: 'Skip error',
              variant: 'danger'
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    voteAndNext (voteAsin, voteProblem, isAlert) {
      this.loading = true

      ApiService.post(`/card/${this.card.id}`, {
        asin: voteAsin,
        problem: voteProblem,
        alert: isAlert
      })
        .then(() => {
          this.next()
          this.$store.commit('reduceCardsCount')
        })
        .catch(error => {
          this.loading = false

          this.$bvToast.toast(getRequestErrorText(error), {
            title: 'Vote error',
            variant: 'danger'
          })
        })
    },
    handleCardClosed (payload) {
      if (this.card.id === payload) {
        this.$bvToast.toast('Карточка была закрыта', {
          title: 'Внимание!',
          variant: 'warning'
        })

        this.next()
      }
    }
  }
}
</script>
